import React from "react"
import links from "../constans/links"
import icons from "../constans/social-icons"
import scrollTo from "gatsby-plugin-smoothscroll"
import { AiOutlineMail } from "react-icons/ai"

import { IoIosClose } from "react-icons/io"

const Sidebar = ({ isOpen, toggleNav }) => {
  return (
    <nav className={`sidebar ${isOpen ? "sidebar-active" : ""}`}>
      <a className="sidebar__exit-btn" onClick={toggleNav}>
        <IoIosClose />
      </a>
      <ul className="sidebar__list">
        {links.map((link, index) => {
          return (
            <li key={index} className="sidebar__item">
              <a
                onClick={() => {
                  scrollTo(link.path)
                  toggleNav()
                }}
                className="sidebar__link"
              >
                {link.text}
              </a>
            </li>
          )
        })}
        <div className="sidebar__icons">
          {icons.map((icon, index) => {
            return (
              <a className="sidebar__icon" href={icon.url}>
                {icon.icon}
              </a>
            )
          })}
        </div>
        <a href="mailto:flodj@hotmail.co.uk" className="nav__mail">
          <span className="nav__mail--icon">
            <AiOutlineMail />
          </span>
          flovideoediting@gmail.com
        </a>
      </ul>
    </nav>
  )
}

export default Sidebar
