import React from "react"
import icons from "../constans/social-icons"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="row">
        <div className="footer__container">
          <div className="footer__item">
            <div className="footer__social">
              {icons.map((icon, index) => {
                return (
                  <div className="footer__icon-box" key={index}>
                    <a
                      key={index}
                      target="_blank"
                      href={icon.url}
                      target="_blank"
                      className="footer__icon"
                    >
                      {icon.icon}
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="footer__copyright">
        <p className="footer__copyright--text">
          florent dupont -joshua &copy; {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  )
}

export default Footer
