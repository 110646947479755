import React from "react"
import { IoIosClose } from "react-icons/io"

const Modal = ({ selectedVideo, setSelectedVideo }) => {
  const handleClick = e => {
    setSelectedVideo(null)
  }

  return (
    <div className="modal">
      <div className="modal__box" onClick={handleClick}>
        <span className="modal__close" onClick={handleClick}>
          <IoIosClose />
        </span>
        <iframe
          className="modal__box-video"
          src={selectedVideo}
          frameBorder="0"
          border="0"
          allowFullScreen
          allow="fullscreen"
          showInfo="0"
        ></iframe>
      </div>
    </div>
  )
}

export default Modal
