import React, { useState, useEffect } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import links from "../constans/links"
import icons from "../constans/social-icons"
import { AiOutlineMail } from "react-icons/ai"

const Navbar = ({ toggleNav }) => {
  const [sticky, setSticky] = useState(false)

  const handleNav = () => {
    let position = window.pageYOffset

    if (position > 300) {
      setSticky(true)
    } else if (position < 300) {
      setSticky(false)
    } else {
      setSticky(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleNav)

    return () => {
      window.removeEventListener("scroll", handleNav)
    }
  }, [])

  return (
    <nav className={`nav ${sticky ? "sticky" : ""}`}>
      <div className="nav__logo-box">
        <h1 className="nav__logo">
          Florent Dupont -Joshua
          <span className="nav__logo-sm">Video Editor & Filmmaker</span>
        </h1>
      </div>
      <ul className="nav__list">
        {links.map((link, index) => {
          return (
            <li className="nav__item" key={index}>
              <a onClick={() => scrollTo(link.path)} className="nav__link">
                {" "}
                {link.text}
              </a>
            </li>
          )
        })}
        <a href="mailto:flodj@hotmail.co.uk" className="nav__mail">
          <span className="nav__mail--icon">
            <AiOutlineMail />
          </span>
          flovideoediting@gmail.com
        </a>
        <div className="nav__icons">
          {icons.map((icon, index) => {
            return (
              <a
                target="_blank"
                className="nav__icons--icon"
                href={icon.url}
                key={index}
              >
                {icon.icon}
              </a>
            )
          })}
        </div>
      </ul>
      <div className="nav__burger" onClick={toggleNav}>
        <div className="nav__burger--line"></div>
        <div className="nav__burger--line"></div>
        <div className="nav__burger--line"></div>
      </div>
    </nav>
  )
}

export default Navbar
