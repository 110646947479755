import React from "react"
import img1 from "../images/red bull logo.png"
import img2 from "../images/gopro_logo.png"
import img3 from "../images/swatch-logo.png"
import img4 from "../images/EpicTV_LOGO.png"
import img5 from "../images/freeridelogo.png"
import img6 from "../images/logo_ebu.png"
import img7 from "../images/longines.png"
import img8 from "../images/One_HD_logo.svg"
import img9 from "../images/arte_logo.png"
import img10 from "../images/mpc logo.png"
export default [
  {
    image: img1,
    alt: "worked as video editor and filmmaker for redbull",
    link: "https://www.redbull.com/",
  },
  {
    image: img2,
    alt: "worked as video editor and filmmaker for gopro",
    link: "https://gopro.com/",
  },
  {
    image: img3,
    alt: "worked as video editor and filmmaker for swatch",
    link: "https://www.swatch.com/",
  },
  {
    image: img4,
    alt: "worked as video editor and filmmaker for epictv",
    link: "https://www.epictv.com/",
  },
  {
    image: img5,
    alt: "worked as video editor and filmmaker for freerideworldtour",
    link: "https://www.freerideworldtour.com/",
  },
  {
    image: img6,
    alt: "worked as video editor and filmmaker for ebu",
    link: "https://www.ebu.ch/",
  },
  {
    image: img7,
    alt: "worked as video editor and filmmaker for longines",
    link: "https://www.longines.com/",
  },
  {
    image: img8,
    alt: "worked as video editor and filmmaker for on hd australia",
    link: "#",
  },
  {
    image: img9,
    alt: "worked as video editor and filmmaker for arte tv",
    link: "https://www.arte.tv/en/",
  },
  {
    image: img10,
    alt: "worked as video editor and filmmaker for mpc",
    link: "https://www.moving-picture.com/",
  },
]
