import React, { useState, useEffect } from "react"
import Hero from "../Hero/Hero"
import Footer from "../Footer"
import ScrollBtn from "../ScrollBtn"

import "../../sass/main.scss"

const Layout = ({ children }) => {
  const [loading, setLoading] = useState(true)

  const loaded = () => {
    setLoading(false)
  }

  useEffect(() => {
    if (document.readyState !== "loading") {
      loaded()
    }
  }, [loading])

  // if (loading) {
  //   return (
  //     <div className="spinner-wrapper">
  //       <div className="spinner">
  //         <h2>
  //           Florent Dupont -Joshua
  //           <br />
  //           <span>Video Editor & Filmmaker</span>
  //         </h2>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <>
      <Hero />
      {children}
      <ScrollBtn />
      <Footer />
    </>
  )
}

export default Layout
