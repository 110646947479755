import React from "react"
import signature from "../../images/potpis.png"
import Image from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    file(relativePath: { eq: "flo.jpg" }) {
      img1: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    files: file(relativePath: { eq: "background.jpg" }) {
      img2: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    back: file(relativePath: { eq: "snow.JPG" }) {
      backImg: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const About = () => {
  const {
    file: { img1 },
    files: { img2 },
    back: { backImg },
  } = useStaticQuery(query)

  return (
    <section className="about" id="about">
      <div className="row">
        <div className="about__container">
          {/* <BackgroundImage
            Tag="div"
            fluid={backImg.fluid}
            preserveStackingContext={true}
            className="about__img-box"
          > */}
          <div className="about__img-box">
            <Image
              fluid={img1.fluid}
              className="about__img--1"
              alt="Florent Dupont-Joshua - FREELANCE VIDEO EDITOR and FILMMAKER with over 15 years of experience.With a strong background in TV/Film and online based projects, I have edited thousands of hours of broadcast-standard programmes content for a large variety of companies including MPC London , ARTE France, ONE HD Australia. I also have extensive experience in editing sports content for Skiing/ Snowboarding, Climbing, Alpinism, Ultra trail running, Paragliding, Ice skating, Yoga with clients including Longines, Red Bull, GoPro, EpicTv and European Broadcasting Union.
    I enjoy being creative with storytelling using animations, motion graphics, music, sound design, textual elements, and language translations/ subtitles, colour correcting/ grading to create visually-engaging and professional films"
            />
            <Image
              fluid={img2.fluid}
              className="about__img--2"
              alt="Florent Dupont-Joshua - FREELANCE VIDEO EDITOR and FILMMAKER with over 15 years of experience.With a strong background in TV/Film and online based projects, I have edited thousands of hours of broadcast-standard programmes content for a large variety of companies including MPC London , ARTE France, ONE HD Australia. I also have extensive experience in editing sports content for Skiing/ Snowboarding, Climbing, Alpinism, Ultra trail running, Paragliding, Ice skating, Yoga with clients including Longines, Red Bull, GoPro, EpicTv and European Broadcasting Union.
    I enjoy being creative with storytelling using animations, motion graphics, music, sound design, textual elements, and language translations/ subtitles, colour correcting/ grading to create visually-engaging and professional films"
            />
          </div>

          {/* </BackgroundImage> */}

          <div className="about__text-box">
            <h4 className="logo-text-sm  margin-bottom-small">about me</h4>

            <p className="big-text margin-bottom-small">
              <strong className="big-text">
                I'm Florent Dupont-Joshua, a seasoned Video Editor & Filmmaker
                accumulating over 15 years of industry expertise.
              </strong>
            </p>

            <p className="long-text margin-bottom-smallest">
              With a robust foundation in TV/Film and online projects, I've
              meticulously filmed and edited thousands of broadcast-standard
              hours for renowned Production Companies and Tv Channels including
              MPC London, ARTE France, ONE HD Australia, and Channel 4 United
              Kingdom. I have worked directly with brands such as Longines, Red
              Bull, GoPro and La Sportiva. As an Editor efficiency underpins my
              approach, thriving within tight deadlines while upholding
              meticulous attention to detail. My canvas is not limited to mere
              editing; I am a storyteller, infusing creativity through
              animations, motion graphics, music, sound design, language
              translations/subtitles, and color grading to yield
              visually-engaging professional films.
            </p>
            <p className="long-text margin-bottom-smallest">
              As a Cameraman, it's not just about capturing moments; I use the
              camera to be creative, finding the right angles and lighting to
              make each scene visually engaging. When I edit I shape the story
              in post-production but filming I do it in real-time, making sure
              every shot captures not just what's happening but the feelings
              behind it. Whether it's capturing a dancer's elegance or the
              excitement of a sports event, I bring a dynamic and creative touch
              to every project, creating stories that leave a lasting
              impression.
            </p>
            <p className="long-text margin-bottom-smallest">
              It's not just about meeting expectations; it's about exceeding
              them. If you're ready to embark on a cinematic journey that goes
              beyond the ordinary, let's collaborate and create something
              extraordinary.
            </p>

            {/* <div className="about__signature-box">
              <img className="about__signature" src={signature} alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
