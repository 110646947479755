import React from "react"
import skills from "../../constans/skills"
import Title from "../Title"

const Skills = () => {
  return (
    <section className="skills">
      <h2 className="contact__heading">skills</h2>
      <div className="row">
        <div className="skills__skill-box">
          {skills.map((skill, index) => {
            return (
              <div className="skills__skill-item" key={index}>
                <span className="skills__skill-icon">{skill.icon}</span>
                <h4 className="skills__skill-title">{skill.title}</h4>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Skills
