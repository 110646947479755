import React from "react"
import { FaVimeoV, FaYoutube, FaLinkedinIn } from "react-icons/fa"
export default [
  {
    icon: <FaVimeoV />,
    url: "https://vimeo.com/florentdj",
  },
  {
    icon: <FaLinkedinIn />,
    url: "https://www.linkedin.com/in/flovideoediting/",
  },
  {
    icon: <FaYoutube />,
    url: "https://www.youtube.com/channel/UCEb8QJpjQmjYJGfyEQhqXWQ",
  },
]
