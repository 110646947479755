import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "../images/favicon.png"

const query = graphql`
  {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        siteUrl
      }
    }
  }
`

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query)
  const { siteDesc, siteTitle, siteUrl } = site.siteMetadata

  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={`${title} | ${siteTitle}`}>
      <meta name="description" content={description || siteDesc}></meta>
      <link rel="icon" href={favicon} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <meta property="og:url" content={siteUrl} />

      <meta property="og:type" content="website" />

      <meta property="og:title" content={siteTitle} />

      <meta property="og:description" content={siteDesc} />
      <meta
        name="keywords"
        content="Florent Dupont-Joshua,
       Video Editing, Video Editor, Filmmakker, Video editor and Filmmmaker, TV, Films, MPC London ,
        ARTE France, ONE HD Australia, Skiing/ Snowboarding videos, Skiing/ Snowboarding videos, Climbing videos, Alpinism videos, UK video editing, France video editing, Chamonix video editing, London video editing, Switzerland video editing,
         Geneva video editing, England video editing"
      ></meta>
    </Helmet>
  )
}

export default SEO
