import React, { useState } from "react"

const Contact = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [subjectError, setSubjectError] = useState("")
  const [messageError, setMessageError] = useState("")

  console.log(name)

  const checkForm = e => {
    if (name === "") {
      e.preventDefault()
      setNameError("Name is required!")
    } else {
      setNameError("")
    }

    if (email === "") {
      e.preventDefault()
      setEmailError("Email is required!")
    } else {
      setEmailError("")
    }

    if (subject === "") {
      e.preventDefault()
      setSubjectError("Subject is required!")
    } else {
      setSubjectError("")
    }

    if (message === "") {
      e.preventDefault()
      setMessageError("Message is required!")
    } else {
      setMessageError("")
    }
  }

  return (
    <section className="contact" id="contact">
      <h2 className="contact__heading">contact</h2>
      <div className="row">
        <div className="contact__container margin-bottom-medium">
          <form
            action="https://formspree.io/f/xgeppakp"
            method="POST"
            id="form"
            className="contact__form"
            onSubmit={checkForm}
          >
            <input
              type="text"
              name="name"
              className="contact__input margin-right"
              onChange={e => setName(e.target.value)}
              placeholder="Name"
            />
            <span className="contact__error">{nameError}</span>
            <input
              type="email"
              name="email"
              className="contact__input margin-right"
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"
            />
            <span className="contact__error">{emailError}</span>
            <input
              type="text"
              name="subject"
              className="contact__input-middle"
              onChange={e => setSubject(e.target.value)}
              placeholder="Subject"
            />
            <span className="contact__error">{subjectError}</span>
            <textarea
              className="contact__text"
              name="message"
              cols="30"
              rows="10"
              onChange={e => setMessage(e.target.value)}
              placeholder="Message"
            ></textarea>
            <span className="contact__error">{messageError}</span>
            <div className="contact__btn-box">
              <button className="contact__btn" name="submit" type="submit">
                send
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact
