import React from "react"
import Title from "../Title"
import logos from "../../constans/logos"

const Clients = () => {
  return (
    <section className="clients">
      <div className="row">
        <Title title="clients" />

        <div className="clients__container">
          {logos.map((logo, index) => {
            return (
              <div className="clients__item" key={index}>
                <a target="_blank" href={logo.link}>
                  <img
                    className="clients__img"
                    src={logo.image}
                    alt={logo.alt}
                  />
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Clients
