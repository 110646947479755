import React, { useState } from "react"
import Title from "../Title"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    videos: allContentfulVideo(sort: { fields: order, order: ASC }) {
      nodes {
        videoLink
        title
        description {
          description
        }
      }
    }
  }
`

const Projects = ({ setSelectedVideo }) => {
  const [allProjects, setAllProjects] = useState(true)

  const {
    videos: { nodes },
  } = useStaticQuery(query)

  return (
    <section className="projects" id="projects">
      <div className="row">
        <Title title="my projects" />
        <div className="projects__container">
          {allProjects
            ? nodes.slice(0, 6).map((item, index) => {
                const { title, description, videoLink } = item
                return (
                  <div className="projects__item" key={index}>
                    {videoLink.includes("https://www.youtube.com/embed/") ||
                    videoLink.includes("https://player.vimeo.com/video/") ? (
                      <div className="projects__video">
                        <span
                          className="projects__full-screen"
                          onClick={() => setSelectedVideo(videoLink)}
                        ></span>
                        <iframe
                          className="projects__video-link"
                          src={videoLink}
                        ></iframe>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="projects__text">
                      <h2 className="projects__title">{title}</h2>
                      <p className="projects__desc">
                        {description.description}
                      </p>
                    </div>
                  </div>
                )
              })
            : nodes.map((item, index) => {
                const { title, description, videoLink } = item
                console.log("ITEM", item)
                return (
                  <div className="projects__item" key={index}>
                    {videoLink.includes("https://www.youtube.com/embed/") ||
                    videoLink.includes("https://player.vimeo.com/video/") ? (
                      <div className="projects__video">
                        <span
                          className="projects__full-screen"
                          onClick={() => setSelectedVideo(videoLink)}
                        ></span>
                        <iframe
                          className="projects__video-link"
                          src={videoLink}
                        ></iframe>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="projects__text">
                      <h2 className="projects__title">{title}</h2>
                      <p className="projects__desc">
                        {description.description}
                      </p>
                    </div>
                  </div>
                )
              })}
        </div>
      </div>
      <div className="projects__btn-box">
        {nodes.length > 6 ? (
          <a
            className="projects__btn"
            onClick={() => setAllProjects(!allProjects)}
          >
            {allProjects ? "show more" : "show less"}
          </a>
        ) : (
          ""
        )}
      </div>
    </section>
  )
}

export default Projects
