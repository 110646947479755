import React from "react"
import { BsCameraVideo, BsLaptop } from "react-icons/bs"
import { GiMagnifyingGlass, GiCash } from "react-icons/gi"
import { BiTask } from "react-icons/bi"
import { SiAdobecreativecloud } from "react-icons/si"
import { RiMovie2Line } from "react-icons/ri"
export default [
  {
    icon: <BsCameraVideo />,
    title: "Extensive experience finding the story in your material",
  },
  {
    icon: <GiMagnifyingGlass />,
    title: "Attention to detail and adherence to brand guidelines",
  },
  {
    icon: <GiCash />,
    title: "Results focused with an ability to work to deadlines & budget",
  },
  {
    icon: <SiAdobecreativecloud />,
    title:
      "Expert technical skills in Adobe Premiere Pro, After Effects, Photoshop, Audition and Illustrator ",
  },
  {
    icon: <RiMovie2Line />,
    title:
      "Fluency with DaVinci Resolve, Final Cut 7/X and Avid Media composer ",
  },
  {
    icon: <BsLaptop />,
    title: "Flexibility to work remotely or on site",
  },
]
