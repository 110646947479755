import React, { useState, useEffect } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import Navbar from "../Navbar"
import Sidebar from "../Sidebar"
import { useStaticQuery, graphql } from "gatsby"
import { AiOutlineArrowDown } from "react-icons/ai"

const query = graphql`
  {
    contentfulHeroVideo {
      video {
        file {
          url
        }
      }
    }
  }
`

const Hero = () => {
  const {
    contentfulHeroVideo: {
      video: {
        file: { url },
      },
    },
  } = useStaticQuery(query)

  const [isOpen, setIsOpen] = useState(false)

  const toggleNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <header className="header">
      <Navbar toggleNav={toggleNav} />
      <Sidebar isOpen={isOpen} toggleNav={toggleNav} />
      <div
        className="header__video-box"
        dangerouslySetInnerHTML={{
          __html:
            `     
        <video
          src=` +
            '"' +
            url +
            '"' +
            `
          autoPlay
          loop
          muted
          playsinline
        ></video>             
      `,
        }}
      />
      <a onClick={() => scrollTo("#projects")} className="header__arrow">
        <AiOutlineArrowDown />
      </a>
    </header>
  )
}

export default Hero
