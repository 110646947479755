import React, { useState } from "react"
import Layout from "../components/Layouts/Layout"
import About from "../components/Home/About"
import Projects from "../components/Home/Projects"
import Modal from "../components/Home/Modal"
import Clients from "../components/Home/Clients"
import Contact from "../components/Home/Contact"
import Skills from "../components/Home/Skills"
import SEO from "../components/SEO"

export default function Home() {
  const [selectedVideo, setSelectedVideo] = useState(null)

  console.log(selectedVideo)

  return (
    <Layout>
      <SEO title="Florent Dupont -Joshua" />
      <Projects setSelectedVideo={setSelectedVideo} />
      {selectedVideo && (
        <Modal
          setSelectedVideo={setSelectedVideo}
          selectedVideo={selectedVideo}
        />
      )}
      <Skills />
      <Clients />
      <About />
      <Contact />
    </Layout>
  )
}
