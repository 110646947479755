export default [
  {
    path: "#projects",
    text: "projects",
  },
  {
    path: "#about",
    text: "about",
  },
  {
    path: "#contact",
    text: "contact",
  },
]
